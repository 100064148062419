import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { withTrans } from "../i18n/withTrans"
import { Link } from "gatsby"
import Text from "../components/text"

const Impressum = ({t, ...props}) => (
  <Layout>
    <Seo title="Impressum"/>
    <main id="maincontent">
    <div style={{paddingTop: "30vh", paddingBottom: "20vh", paddingLeft:"15vw", paddingRight: "15vw", margin:"10px"}}>
        <h1>{t("impressum")}</h1>


        <h2 className="p11B">Verantwortlicher gem. DSGVO</h2>
        <p className="p11GM">
        <Text text={`
          Stadt Ulm
          Rathaus
          Marktplatz 1
          89073 Ulm
          Telefon: +49 (0)731 161-0
          Fax: +49 (0)731 161-1613
          info@ulm.de
          Die Stadt Ulm ist eine Körperschaft des öffentlichen Rechts. Sie wird vertreten durch Oberbürgermeister Gunter Czisch.

        `}/>
        </p>

        <h2 className="p11B">Zuständige Aufsichtsbehörde</h2>
        <p className="p11GM">
        <Text text={`
          Regierungspräsidium Tübingen
          Konrad-Adenauer-Straße 20
          72072 Tübingen

          Umsatzsteuer-Identifikationsnummer: DE 1470 39924

          Bankverbindung:
          Sparkasse Ulm (BLZ 630 500 00), Konto 100072
          IBAN: DE 27 6305 0000 0000 1000 72
          SWIFT-BIC: SOLADES1ULM
          Gläubiger-Identifikationsnummer: DE41ULM00000033860

        `}/>
        </p>

        <h2 className="p11B">Inhaltlich Verantwortlicher</h2>
        <p className="p11GM">
        <Text text={`
          HfG-Archiv Ulm | Am Hochsträss 8 | D-89081 Ulm | Deutschland
          Telefon + 49 (0)731 161-4370 | hfg-archiv@ulm.de
          Redaktion: Viktoria Lea Heinrich M.A., HfG-Archiv Ulm
          Inhalt: Christopher Haaf M.A., Viktoria Lea Heinrich M.A., Linus Rapp M.A.
          Projektleitung: Prof. Dr. Thomas Hensel, Hochschule Pforzheim, Dr. Martin Mäntele, HfG-Archiv Ulm, Prof. Dr. Steffen Siegel, Folkwang Universität der Künste, Essen
          Übersetzung: Peter Blakeney & Christine Schöffler
          Gestaltung & Konzept: Studio Erika, Kempten
          Technische Umsetzung: Julian Karrer

          Alle Abbildungen dieser Online-Publikation sind urheberrechtlich geschützt.
          Wenn nicht anders verzeichnet, liegen die Bildrechte beim HfG-Archiv Ulm, ansonsten bei dem jeweiligen Inhaber des Copyrights.

        `}/>
        </p>

        <h2 className="p11B">Hinweise zur Online-Streitbeilegung</h2>
        <p className="p11GM">
        <Text text={`
          Online-Streitbeilegung gemäß Art. 14 Abs. 1 ODR-VO:
          Die Plattform der Europäischen Kommission zur Online-Streitbeilegung (OS) finden Sie hier: http://ec.europa.eu/consumers/odr/
          Nutzerinnen und Nutzer verpflichten sich, die Persönlichkeitsrechte und das Recht auf informationelle Selbstbestimmung Dritter zu wahren.
          Nutzerinnen und Nutzer stellen das Stadtarchiv Ulm von Haftung bei der Verletzung von Rechten Dritter frei.
          Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind wir nicht verpflichtet und nicht bereit.

        `}/>
        </p>

        <h2 className="p11B">Haftung für Inhalte</h2>
        <p className="p11GM">
        <Text text={`
          Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
          Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.

        `}/>
        </p>

        <h2 className="p11B">Haftung für Links</h2>
        <p className="p11GM">
        <Text text={`
          Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
          Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.

        `}/>
        </p>

        <h2 className="p11B">Urheberrecht</h2>
        <p className="p11GM">
        <Text text={`
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
          Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.

        `}/>
        </p>

        <h2 className="p11B">Datenschutz</h2>
        <p className="p11GM">
        <Text text={`
          Das HfG Archiv Ulm nimmt den Schutz Ihrer persönlichen Daten sehr ernst und hält sich streng an alle geltenden Gesetze und Vorschriften zum Datenschutz. Ausführliche Informationen dazu finden Sie in unserer`}/><Link to="/datenschutz" style={{textDecoration:"none", color:"black"}}>{"Datenschutzerklärung"}</Link>
        </p>


    </div>
    </main>
  </Layout>
)

export default withTrans(Impressum)
